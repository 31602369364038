import {
  Suspense,
  memo,
  useEffect,
  useMemo,
} from 'react'
import { gql } from '@apollo/client'
import { useLocation, useNavigate } from 'd2/hooks/useRouter'
import { useQuerySwitch } from 'd2/hooks/useQuerySwitch'
import AppAnnouncementsBanner from './AppAnnouncementsBanner'
import Footer from 'd2/loadables/Footer'
import IosPwaPrompt from 'd2/components/IosPwaPrompt'
import LoadingSpinner from 'd2/components/LoadingSpinner'
import MainNavBarD2 from 'd2/loadables/MainNavBarD2'
import ObserverModeBanner from './ObserverModeBanner'
import SnackbarNotification from 'd2/components/SnackbarNotification'
import StorageSpaceBanner from 'd2/components/StorageSpaceBanner'
import useStyles from './styles'
import type { DashboardLayout } from 'd2/queries'
import type { DocumentNode } from 'd2/types'
import type { Props } from './types'
import type { ReducerArg } from 'd2/hooks/useQuerySwitch'

const QUERY: DocumentNode = gql`
  query DashboardLayout {
    observer_signed_in
    payment_announcements
    winter_break_announcement
    me {
      id
      display_name_or_email
      email_confirmed
      is_admin
      tipalti_user_data {
        id
        must_submit_tax_form
      }
    }
    current_observer {
      display_name_or_email
      id
    }
  }
`

const reducer = ({ data }: ReducerArg<DashboardLayout>) => data
const useQuery = () => useQuerySwitch<DashboardLayout, EO, typeof reducer>(QUERY, {
  reducer,
})

const VERIFY_EMAIL_PATH = '/d2/verify_email'
const FAQ_PATH = '/d2/faq'

const Dashboard = memo<Props>(({
  children,
  header,
  storageSpaceBanner,
}) => {
  const { classes } = useStyles()
  const navigate = useNavigate()
  const location = useLocation()
  const [data] = useQuery()

  useEffect(() => {
    if (!data || data.current_observer) return
    const { me, observer_signed_in: observerSignedIn } = data
    const { email_confirmed: emailConfirmed, is_admin: isAdmin, tipalti_user_data: tipaltiUserData } = me
    const { pathname } = location

    if (!emailConfirmed && !isAdmin && !observerSignedIn && pathname !== VERIFY_EMAIL_PATH && pathname !== FAQ_PATH) {
      navigate('/d2/verify_email')
    }
    if (tipaltiUserData?.must_submit_tax_form) {
      navigate('/d2/tax_submission')
    }
  }, [data, navigate, location])

  // TODO: Better handle header() and storageSpaceBanner() as memoized functions or true components?

  const fallback = useMemo(() => <LoadingSpinner />, [])
  const fallbackFillHeight = useMemo(() => <LoadingSpinner fillHeight />, [])

  return (
    <>
      <div className={classes.container}>
        <Suspense fallback={fallback}>
          { header ? header() : <MainNavBarD2 /> }
        </Suspense>
        { data?.observer_signed_in && data.current_observer && (
          <Suspense fallback={fallback}>
            <ObserverModeBanner
              observeeName={data.me.display_name_or_email}
              observerName={data.current_observer.display_name_or_email}
            />
          </Suspense>
        ) }
        { data?.payment_announcements
          && <Suspense fallback={fallback}>
            <AppAnnouncementsBanner
              message={data.payment_announcements}
              testId="AppAnnouncementsBanner-PaymentsAnnouncements"
            />
          </Suspense> }
        { data?.winter_break_announcement
          && <Suspense fallback={fallback}>
            <AppAnnouncementsBanner
              message={data.winter_break_announcement}
              testId="AppAnnouncementsBanner-WinterBreakAnnouncement"
            />
          </Suspense> }
        <Suspense fallback={fallback}>
          { storageSpaceBanner
            ? storageSpaceBanner()
            : <StorageSpaceBanner /> }
        </Suspense>
        <div className={classes.content}>
          <Suspense fallback={fallbackFillHeight}>
            { children }
          </Suspense>
        </div>
      </div>
      <Suspense fallback={fallback}>
        <IosPwaPrompt />
      </Suspense>
      <Suspense fallback={fallback}>
        <Footer />
      </Suspense>
      <Suspense fallback={fallback}>
        <SnackbarNotification />
      </Suspense>
    </>
  )
})

Dashboard.displayName = 'Dashboard'

export default Dashboard
